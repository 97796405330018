.enable_plugin_inline_editable() when(@enable-plugin-inline-editable = true) {

.editable-container .popover-title {
	color:#438EB9;
}
.editable-click {
	border-bottom:1px dashed #BBB;
	cursor:pointer;
	font-weight:normal;
	
	img& {
		border:1px dotted #BBB;
	}
	&:hover {
		border-color:#0088CC;
		color:#0088CC;
		img& {.opacity(0.75);}
	}
}


.editable-buttons , .editable-input {
	display:inline-block;
}
.editable-buttons {
	margin-left: 1px;
	.btn {
		padding: 0;
		width: 28px;
		line-height: 24px;
		border-width: 3px;
		font-size: @base-font-size - 1;
		margin: 0 1px 0 0;
		> .@{icon} {
			margin: 0;
		}
	}
}
.editable-clear-x {
	cursor:pointer;
	color:#888;
	background:none;
	&:hover {
		color:#D15B47;
	}
	
	&:before {
		display:inline-block;
		content:"\f057";
 
		font-family: FontAwesome;
		font-size: @font-size-editable-clear;
	
		position: absolute;
		margin-top: -9px;
		width: 16px;
		height: 30px;
		line-height: 30px;
		text-align:center;
	}
}



.editable-input .ace-spinner {
 margin-right: 8px;
 .spinner-input {
	width: 100%;
 }
}


.editable-inline .editable-slider {
  margin-top: 10px;
  margin-right: 4px;
}
.editable-popup .editable-slider {
	display: block;
	margin-bottom: 16px;
	margin-top: 4px;
}
.editable-slider input[type=text] {
	display: none;
}
.editable-slider input[type=range] {
	outline: none !important;
}


.editable-input .ace-file-input {
	display:block;
}
.editable-image .ace-file-multiple .ace-file-container.selected {
	border-color:transparent;
}

.editable-image + .editable-buttons  , .editable-wysiwyg + .editable-buttons {
	display:block;
	text-align:center;
	margin-top:8px;
}

.editable-wysiwyg {
	width:95%;
	.wysiwyg-editor  {
		height:auto;
		overflow-y:hidden;
	}
}


.editableform {
	.input-append.dropdown-menu {
		display:none;
	}
	.open .input-append.dropdown-menu {
		display:block;
	}
}

.editable-container .editableform {
	margin-bottom:10px;
}
.editable-inline .editableform {
	margin-bottom:0;
}
.editableform .control-group {
	display: block;
}


.editableform-loading {
	background:none;
	.@{icon} , .progress{
		position:relative;
		top:35%;
	}
}


.input-group.date .input-group-addon {
	cursor:pointer;
}


//IE!!!!
//.editable-input .input-group .input-group-addon {
 ///width: auto \9;
//}




}
.enable_plugin_inline_editable();