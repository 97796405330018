//sidebar is automatically minimized in smaller views

.enable_minimized_responsive_menu() when(@enable-minimized-responsive-menu = true) {

	.sidebar-toggle.sidebar-expand {
		display: none;
	}
	@media (max-width: @grid-float-breakpoint-max) {
	 .menu-toggler.invisible {
		position: fixed;
		z-index: -999;
		visibility: hidden;
		opacity: 0;
		width: 1px;
		height: 1px;
	 }

	 .sidebar.responsive-min {
		.menu_min();
		
		&:before {
			display: block;
		}
		.sidebar-toggle.sidebar-collapse {
			display: none;
		}
		.sidebar-toggle.sidebar-expand {
			display: block;
		}

		+ .main-content {
			margin-left: @sidebar-min-width !important;
			.rtl & {
				margin-left: auto !important;
				margin-right: @sidebar-min-width !important;
			}
			.breadcrumb {
				margin-left: @breadcrumb-margin-left;
			}
		}
	 }

	 .sidebar.responsive-max {
		display: block;
		position: relative;
		left: @sidebar-width;
		margin-left: -(@sidebar-width);

		z-index: @zindex-sidebar-fixed;
		
		.box-shadow(~"2px 1px 2px 0 rgba(0,0,0,0.15)");
		
		&:before {
			display: none;
		}

		.sidebar-toggle {
			&.sidebar-collapse {
				display: none;
			}
			&.sidebar-expand {
				display: block;
				z-index: 1;//to go above ul:before, etc when scrolled
			}
		}

		+ .main-content {
			margin-left: (@sidebar-min-width) !important;
			.breadcrumb {
				margin-left: @breadcrumb-margin-left;
			}
		}
	 }

	}


}
.enable_minimized_responsive_menu();