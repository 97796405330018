.enable_progressbar() when(@enable-progressbar = true) {

//progressbar
@progress-bg: #e9e9e9;

.progress {
 .border-radius(0);
 .box-shadow(none);
  background: @progress-bg;

 height: 18px;

 .progress-bar {
	.box-shadow(none);
	line-height:18px;
 }

 &[data-percent] {
	&:after {
		 display: inline-block;
		 content: attr(data-percent);
		 color: #FFF;
		 position: absolute;
		 left: 0;
		 right: 0;
		 top: 0;
		 bottom: 0;
		 
		 line-height: 16px;
		 
		 text-align: center;
		 font-size: @base-font-size - 1;
		 //font-family: Verdana;
	}
 }
 &.progress-yellow[data-percent]:after {
	color: #996633;
 }


 &.progress-small {
	height: 12px;
	.progress-bar {
		line-height: 10px;
		font-size: @base-font-size - 2;
	}
	&[data-percent]:after {
		line-height: 10px;
		font-size: @base-font-size - 2;
	}
 }


 &.progress-mini {
	height: 9px;
	.progress-bar {
		line-height: 8px;
		font-size: @base-font-size - 2;
	}
	&[data-percent]:after {
		line-height: 8px;
		font-size: @base-font-size - 2;
	}
 }
}



.progress-bar {
 .progress-bar-variant(@progress-color);
}
.progress-bar-danger {
 .progress-bar-variant(@progress-danger);
}
.progress-bar-success {
 .progress-bar-variant(@progress-success);
}
.progress-bar-warning {
 .progress-bar-variant(@progress-warning);
}
.progress-bar-pink {
 .progress-bar-variant(@progress-pink);
}
.progress-bar-purple {
 .progress-bar-variant(@progress-purple);
}
.progress-bar-yellow {
 .progress-bar-variant(@progress-yellow);
}
.progress-bar-inverse {
 .progress-bar-variant(@progress-inverse);
}
.progress-bar-grey {
 .progress-bar-variant(@progress-grey);
}




/**
.progress {
  position: relative;
}
.progress:before {
  display: inline-block;
  content: "";
  
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
 
  background: radial-gradient(9px 9px 0deg, circle cover, aqua 0%, rgba(0, 0, 255, 0) 100%, blue 95%);
}
*/


}
.enable_progressbar();