//some extra form variables
//more important ones are inside "variables.less"

@input-placeholder-color:#C0C0C0;
@input-readonly-color:#939192;
@input-readonly-bg:#F5F5F5;
@input-readonly-focus-border:#AAA;
@input-readonly-focus-bg:#F9F9F9;
@input-readonly-focus-shadow:~"0px 0px 0px 2px rgba(150, 150, 150, 0.3)";

@input-disabled-color:#848484;
@input-disabled-bg:#EEE;

@input-hover-border:lighten(greyscale(@input-border-focus),10%);
 
@option-hover-bg: #EEE;//desaturate(darken(#EEF5FA , 1%) , 4%);


//form error states
@success-state-border:lighten(#92BF65 , 4%);
@success-state-color:#8BAD4C;
@success-state-focus-border: darken(#81A85A , 4%);
@success-state-focus-color:darken(@success-state-color, 10%);
@success-state-focus-shadow:~"0px 0px 0px 2px rgba(130, 188, 58, 0.3)";
@success-state-text-color:#7BA065;//the color of the help text, etc inside that control label
@success-state-background-color: lighten(@success-state-border , 34.5%);

@error-state-border:lighten(#F09784 , 4%);
@error-state-color:#D68273;
@error-state-focus-border: darken(#DB8978 , 4%);
@error-state-focus-color:darken(@error-state-color, 10%);
@error-state-focus-shadow:~"0px 0px 0px 2px rgba(219, 137, 120, 0.3)";
@error-state-text-color:#D16E6C;
@error-state-background-color: lighten(@error-state-border , 21.5%);

@warning-state-border:lighten(#E0C43A , 4%);
@warning-state-color: #D3BD50;
@warning-state-focus-border: darken(#D8BC41 , 4%);
@warning-state-focus-color: darken(@warning-state-color, 10%);
@warning-state-focus-shadow:~"0px 0px 0px 2px rgba(216, 188, 65, 0.3)";
@warning-state-text-color:#D19D59;
@warning-state-background-color: lighten(@warning-state-border , 38%);

@info-state-border:lighten(#64A6BC , 4%);
@info-state-color:#4B89AA;
@info-state-focus-border: darken(#64A6BC , 10%);
@info-state-focus-color: darken(@info-state-color, 10%);
@info-state-focus-shadow:~"0px 0px 0px 2px rgba(58, 120, 188, 0.3)";
@info-state-text-color:#657BA0;
@info-state-background-color: lighten(@info-state-border , 35.5%);

@disabled-state-color:#848484;
@disabled-state-bg:#EEE;





//form elements
.form-line {
  margin-bottom:24px; padding-bottom:12px;
  border-bottom:1px solid #EEE;
}
.form-actions {
  display:block;

  background-color: #F5F5F5;
  border-top: 1px solid #E5E5E5;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 19px 20px 20px;
}

.help-button {
  display: inline-block;
  height: 22px;
  width: 22px;
  line-height: 22px;
  text-align: center;
  padding: 0;
  background-color: @help-button-bg;
  color: #FFF;
  font-size: @font-size-help-button;
  font-weight: bold;
  cursor: default;
 
  margin-left: 4px;
  .border-radius(100%);
  border-color: #FFF;
  border: 2px solid #FFF;
 
  .box-shadow(~"0px 1px 0px 1px rgba(0, 0, 0, 0.2)");
  
  &:hover {
	background-color: @help-button-bg;
	text-shadow: none;
 }
}



label {
 font-weight: normal;
 font-size: @font-size-label;
}
.form-group > label[class*="col-"] {
 //padding-top: 4px;
 margin-bottom: 4px;
}


//label , .lbl {
 //vertical-align: middle;
//}
td > label , th > label
{
 margin-bottom: 0;
 line-height: inherit;
 vertical-align: middle;
}
label.inline {
 margin-bottom: 3px;
}


textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
 .border-radius(0) !important;
 color: @input-color;
 background-color: @input-background;
 border: 1px solid @input-border;
 
 padding: 5px 4px 6px;
 //line-height: 1.3;
 font-size: @font-size-text-input;
 font-family: inherit;

 .box-shadow(none) !important;

 .transition-duration(0.1s);
 
 &:hover {
	border-color: @input-hover-border;
 }
 &:focus {
	//.box-shadow(@input-shadow-focus);
	.box-shadow(none);
	 
	color: @input-color-focus;
	border-color: @input-border-focus;
	background-color: @input-background-focus;
	outline: none;
 }
}

input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
	color: @input-placeholder-color;
}
input:-moz-placeholder, .form-control:-moz-placeholder { //FF 4 - 18
	color: @input-placeholder-color;
	opacity: 1;
}
input::-moz-placeholder, .form-control::-moz-placeholder { //FF 19+
	color: @input-placeholder-color;
	opacity: 1;
}
input:-ms-input-placeholder, .form-control:-ms-input-placeholder { //IE 10+
	color: @input-placeholder-color;
}


.form-control , select {
  //line-height: 1;
  border-radius : 0;
  .box-shadow(none) !important;
   
  color: @input-color;
  background-color: @input-background;
  border:1px solid @input-border;
  &:focus {

	color: @input-color-focus;
	border-color: @input-border-focus;
	background-color: @input-background-focus;
	outline:none;
 }
}
textarea.form-control {
  padding:5px 9px
}
select {
  padding:3px 4px;
  height: 30px;
}
select.form-control {
  padding:4px 6px;
  //height: 32px;
}
select[multiple] , select.form-control[multiple] {
	height: auto;
}
select.input-sm {
 border-radius: 0;
 padding: 2px 3px;
}
select.input-lg {
 border-radius: 0;
 padding: 6px 8px; 
}


input.block {
  display:block;
  margin-bottom:9px;
}


textarea.autosize-transition {
 .transition-duration("height 0.2s");
}
.limiterBox {
  border: 1px solid #222;
  border-top: none;
  background-color: #333;
  padding: 3px 6px;
  font-size: @base-font-size;
  color:#FFF;
  margin-top:6px;
  &:after {
	display:none;
  }
 &:before {
	display:block;
	content:"";
  
	position: absolute;
	width: 0; height: 0;
	top: -8px;
	left: 50%;
	margin-left: -5px;
  
	border-color: transparent;
	border-style: solid;
	border-bottom-color: #333;
	-moz-border-bottom-colors: #333;
	border-width: 0 8px 8px;
 }
}



select {
 & , &.form-control {
	 option {
		padding: 3px 4px 5px;
		&:active , &:hover, &:focus {
			background-color: @option-hover-bg;
			color: #444;
		}
		&.no-option {
			padding: 1px 0;
		}
	 }
 }
}



input[disabled] {
  color: @input-disabled-color !important;
  background-color: @input-disabled-bg !important;
  &:hover {
	border-color: @input-border !important;
  }
}
input[readonly] {
  color: @input-readonly-color;
  background: @input-readonly-bg !important;
  cursor:default;

  &:hover {
	border-color:lighten(@input-readonly-focus-border , 10%);
  }
  &:focus {
	//.box-shadow(@input-readonly-focus-shadow);
	.box-shadow(none);
	border-color: @input-readonly-focus-border;
	background-color: @input-readonly-focus-bg;
  }
}

.help-inline {
 font-size: @font-size-help-inline !important;
}



.input-icon {
 position:relative;
 span& {
	display:inline-block;
 }
 
 > input {
	padding-left:24px;
	padding-right:6px;
 }
 &.input-icon-right > input {
	padding-left:6px;
	padding-right:24px;
 }

 > .@{icon} {
	padding: 0 3px;
	z-index: 2;
	position: absolute;
	top: 1px;
	bottom: 1px;
	left: 3px;
	line-height: 30px;
 
	display: inline-block;
	color: #909090;
	font-size: @font-size-input-icon;
 }
 
 &.input-icon-right > .@{icon} {
	left:auto;
	right:3px;
 }
 
 > input:focus + .@{icon} {
	color:#579;
 }

 ~ .help-inline {
	padding-left:8px;
 }



 //.form-group.has-warning & > .@{icon} { color: @warning-state-color; }
 //.form-group.has-success & > .@{icon} { color: @success-state-color; }
 //.form-group.has-error & > .@{icon} { color: @error-state-color; }
 //.form-group.has-info & > .@{icon} { color: @info-state-color; }
}




//checkboxes , radio and switches
.form-search , .form-inline {
	.radio [type=radio] + label, .checkbox [type=checkbox] + label {
		 float: left;
		 margin-left: -20px;

		.form-search & , .form-inline & {
			margin-left:0;
			margin-right:3px;
		}
	}
}

.input-append , .input-prepend {
 .form-search & .search-query:focus {
	.box-shadow(none);
 }
 input, select, .uneditable-input {
	.border-radius(0);
 }
}



.input-mini {
  width: 60px;
  max-width: 100%;
}

.input-small {
  width: 90px;
  max-width: 100%;
}

.input-medium , .input-md {
  width: 150px;
  max-width: 100%;
}

.input-large {
  width: 210px;
  max-width: 100%;
}

.input-xlarge {
  width: 270px;
  max-width: 100%;
}

.input-xxlarge {
  width: 530px;
  max-width: 100%;
}
input.input-lg {
  font-size: @font-size-large;
}




@import "form/checkbox.less";
@import "form/file.less";



//addon
.input-group {
	.input-group-addon {
		.border-radius(0) !important;	

		.form-group.has-success & {
			border-color: @success-state-border;
		}
		.form-group.has-error & {
			border-color: @error-state-border;
		}
		.form-group.has-warning & {
			border-color: @warning-state-border;
		}
		.form-group.has-info & {
			border-color: @info-state-border;
		}
	}
	
	
	> .btn {
		line-height:20px;
		padding:0 6px;
		.border-radius(0) !important;

		&.btn-sm {
			line-height:22px;
		}
		
		+ .btn{
			margin-left:1px;
		}
	}
	
	> .btn-group > .btn {
		line-height:23px;
		
		&.btn-sm {
			line-height:26px;
		}
	}
	
	> .btn , > .btn-group > .btn {
		& , &.btn-sm {
			> .caret {
				margin-top:10px;
			}
		}
	}
}

.input-group.input-group-compact {
  width: 1px;
}





//input error states
.form-group select,
.form-group textarea,
.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="datetime"],
.form-group input[type="datetime-local"],
.form-group input[type="date"],
.form-group input[type="month"],
.form-group input[type="time"],
.form-group input[type="week"],
.form-group input[type="number"],
.form-group input[type="email"],
.form-group input[type="url"],
.form-group input[type="search"],
.form-group input[type="tel"],
.form-group input[type="color"] {
	background:#FFF;
}
.form-group.has-success {
 input, select, textarea {
	border-color: @success-state-border;
	color: @success-state-color;
	.box-shadow(none);

	&:focus {
		.box-shadow(@success-state-focus-shadow);
		color: @success-state-focus-color;
		border-color: @success-state-focus-border;
		background-color: @success-state-background-color;
		
		+ .@{icon} {
			color: @success-state-color;
		}		
	}
 }
 .@{icon} {
	color: @success-state-color;
 }
 .btn .@{icon} {
	color: inherit;
 }

 .control-label , .help-block  , .help-inline {
	color: @success-state-text-color;
 }
}

.form-group.has-info {
 input , select, textarea {
	border-color: @info-state-border;
	color: @info-state-color;
	.box-shadow(none);

	&:focus {
		.box-shadow(@info-state-focus-shadow);
		color: @info-state-focus-color;
		border-color: @info-state-focus-border;
		background-color: @info-state-background-color;
		
		+ .@{icon} {
			color: @info-state-color;
		}
	}
 }
 .@{icon} {
	color: @info-state-color;
 }
 .btn .@{icon} {
	color: inherit;
 }
 
 .control-label , .help-block  , .help-inline {
	color: @info-state-text-color;
 }
}


.form-group.has-error {
 input , select, textarea {
	border-color: @error-state-border;
	color: @error-state-color;
	.box-shadow(none);

	&:focus {
		.box-shadow(@error-state-focus-shadow);
		color: @error-state-focus-color;
		border-color: @error-state-focus-border;
		background-color: @error-state-background-color;
		
		+ .@{icon} {
			color: @error-state-color;
		}
	}
 }
 .@{icon} {
	color: @error-state-color;
 }
 .btn .@{icon} {
	color: inherit;
 }

 .control-label , .help-block  , .help-inline {
	color: @error-state-text-color;
 }
}



.form-group.has-warning {
 input , select, textarea {
	border-color: @warning-state-border;
	color: @warning-state-color;
	.box-shadow(none);

	&:focus {
		.box-shadow(@warning-state-focus-shadow);
		color: @warning-state-focus-color;
		border-color: @warning-state-focus-border;
		background-color: @warning-state-background-color;
		
		+ .@{icon} {
			color: @warning-state-color;
		}
	}
 }
 .@{icon} {
	color: @warning-state-color;
 }
 .btn .@{icon} {
	color: inherit;
 }

 .control-label , .help-block  , .help-inline {
	color: @warning-state-text-color;
 }
}

.form-group input{
 &[disabled] , &:disabled{
	color: @disabled-state-color !important;
	background-color: @disabled-state-bg !important;
 }
}



@media only screen and (max-width: @screen-xs-max) {
 .help-inline , .input-icon + .help-inline {
  padding-left: 0;
  display: block !important;
 }
}

