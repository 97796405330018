.enable_old_menu_toggle_button() when(@enable-old-menu-toggle-button = true) {
.enable_responsive_menu_otb() when(@enable-responsive-menu = true) {


@toggler-text: "MENU";

@media only screen and (max-width: @grid-float-breakpoint-max) {
 .menu-toggler + .sidebar.responsive {
	margin-top: @breadcrumb-height - 1;
 }
 .main-container .menu-toggler {
	display: block;
	position: absolute;
	//left: auto;
	z-index: @zindex-sidebar-fixed - 1;

	width: 52px;
	height: 32px;
	margin-right: 2px;
	
	line-height: normal;
	padding-left: 33px;
	padding-top: 7px;
	padding-bottom: 1px;
	
	font-size: @font-size-old-toggle-button;
	font-weight: bold;
	text-transform: uppercase;

	

	.box-sizing(content-box);
	
	&:hover {
		text-decoration: none;
	}
	&:focus {
		outline: none;
	}
	
	
	&:before {
		border-top: 1px solid @sidebar-toggler-line-1;
		border-bottom: 1px solid @sidebar-toggler-line-2;
		height: 2px;
		width: 24px;
		content: "";
		position: absolute;
		z-index: 1;
		top: 13px;
		left: 4px;

		.transition(~"all 0.1s ease");
		-o-transition: none;
		
		.box-sizing(content-box);
	}
	
	
	&:after {
		border-top: 1px solid @sidebar-toggler-line-3;
		border-bottom: 1px solid @sidebar-toggler-line-4;
		content: "";
		height: 2px;
		width: 24px;
		
		position: absolute;
		top: 19px;
		left: 4px;

		.transition(~"all 0.1s ease");
		-o-transition: none;
		
		.box-sizing(content-box);
	}

	&.display {
		&:before {
			height: 4px;
			top: 8px;
			border-width: 2px;
		}
		&:after {
			height: 4px;
			top: 20px;
			border-width: 2px;
		}
	}


	> .toggler-text {
		display: block;
		position: absolute;
		bottom: -9px; 
		left: 0;
		border: 1px solid transparent;
		border-width: 9px 42px 0;

		border-top-color: @sidebar-toggler-background;
		-moz-border-top-colors: @sidebar-toggler-background;

		&:after {
			display: block;
			content: @toggler-text;

			color: @sidebar-toggler-color;
			
			position: absolute;
			left: -8px;//change these to adjust text placement
			top: -41px;
		}
	}
 }
 
 

//hide the .nav-list when moving up, otherwise it will move over .menu-toggler!
 .menu-toggler + .responsive.sidebar-scroll .nav-wrap {
	overflow: hidden;
 }
}

@media only screen and (max-width: @screen-tiny) {
 .main-container .menu-toggler {
  width: 0;
  > .toggler-text {
	border-width: 7px 16px;
	bottom: -14px;

	&:after {
		font-size: floor(@font-size-old-toggle-button * 0.75);
		font-weight: normal;
		color: #FFF;

		position: absolute;
		left: -13px;
		top: -42px;
	}
  }

  &:before , &:after {
	margin-top: 8px;
  }
  &.display {
	&:before , &:after {
		height: 2px;
		border-width: 1px;
	}
	&:before {
		top: 13px;
	}
	&:after {
		top: 19px;
	}
  }
 }
}

}
.enable_responsive_menu_otb();
}
.enable_old_menu_toggle_button();