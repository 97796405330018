@media only screen and (max-width: @grid-float-breakpoint-max) {
 body {
	overflow-x : hidden;
 }
}


.navbar-fixed-top + .main-container {
	padding-top: @navbar-min-height;
}
@media (max-width: @screen-topbar-down) {
	.navbar-fixed-top + .main-container {
		padding-top: (@navbar-min-height * 2);
	}
	.navbar-fixed-top.navbar-collapse + .main-container {
		padding-top: @navbar-min-height;
	}
}



// custom grid
@media only screen and (max-width: @screen-topbar-down-small) {
	.grid2 , .grid3 , .grid4{
		 float: none;
		 display: block;
		 width: 96%;
		 border-left-width: 0;
		 position: relative;
		 margin-bottom: 11px;
		 border-bottom: 1px solid #E3E3E3;
		 padding-bottom: 4px;
	}
	.grid2 > [class*="pull-"], .grid3 > [class*="pull-"], .grid4 > [class*="pull-"]{
		float: none !important;
		display: inline-block;
		position: absolute;
		right: 11px;
		top: 0;
		margin-top: 0;
	}
	.grid2:last-child , .grid3:last-child , .grid4:last-child {
		border-bottom-width: 0;
	}
}


@media only screen and (max-width: @screen-xs) {
	.hidden-480{
		 display: none !important;
	}
}
@media only screen and (max-width: @screen-tiny) {
	.hidden-320{
		 display: none !important;
	}
}

