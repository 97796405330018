.enable_plugin_duallist() when(@enable-plugin-duallist = true) {

	.btn-group > .btn.moveall:first-child,
	.btn-group > .btn.remove:first-child {
	 margin: 0;
	}
	.btn-group > .btn.moveall:first-child + .btn.move,
	.btn-group > .btn.remove:first-child + .btn.removeall {
	  margin: 0;
	}

	.bootstrap-duallistbox-container .info {
	  font-size: ceil(@base-font-size * 0.9);
	}

	.bootstrap-duallistbox-container .clear1, .bootstrap-duallistbox-container .clear2 {
	  font-size: ceil(@base-font-size * 0.9);
	}

}
.enable_plugin_duallist();



.multiselect-container > li > a {
 padding: 0;
 > label {
   padding: 7px 10px 7px 20px;
 }
}