.enable_plugin_jquery_ui() when(@enable-plugin-jquery-ui = true) {

.ui-datepicker {
	background-color:#FFF;
	border:1px solid rgba(0, 0, 0, 0.2);
	.box-shadow(@dropdown-shadow);
	
	.ui-datepicker-prev , .ui-datepicker-next {
		height: 26px;
		min-width: 32px;
		max-width:32px;
		text-align: center;
		cursor:pointer;
		color:transparent;
		line-height:26px;
		
		.box-sizing(content-box);
		
		.ui-icon {
			color:transparent;
			visibility:hidden;
		}
		
		&:hover {
			background-color:#EEE;
			text-decoration:none;
		}
		&:before {
			display: inline;
			font-family: FontAwesome;
			font-size: @base-font-size + 1;
			content: "\f060";
			color: @text-color;
		}
		&-hover {
			top:2px;
		}
	}
	.ui-datepicker-next:before {
		content:"\f061";
	}
	.ui-datepicker-prev-hover {
		left: 2px;
	}
	.ui-datepicker-next-hover {
		right: 2px;
	}
	
	
	
	td {
		padding:0;
		> a , > span {
			display:inline-block;
			height: 22px;
			min-width: 24px;
			max-width:24px;
			text-align:center;
			color:@text-color;
			font-size:@base-font-size;
			
			.box-sizing(content-box);
		}
		> a {
			&:hover {
				background-color:#EEE;
			}
			&.ui-state-highlight {
				background-color:#D5E5EF;
			}
			&.ui-state-active{
				background-color:@datepicker-active-bg;
				color:#FFF;
			}
			&.ui-priority-secondary{
				color:#888;
			}
		}
		> span  {
			color:#999;
		}
		
		.ui-datepicker-title select {
			height:24px;
			line-height:24px;
			padding:2px 3px;
		}
		
		.ui-datepicker-buttonpane {
			background-color:#DDD;
			height:1px;
		}
	}
}




/* dialog */
.ui-widget-overlay  {
  background:rgba(0,0,0,0.25);
  .opacity(1) !important;
  z-index: @zindex-modal - 1 !important;
}
.ui-dialog , .ui-jqdialog {
  z-index: @zindex-modal !important;

  background-color:#FFF;
  padding:0;
  border:1px solid #DDD;
  .box-shadow(~"0 2px 4px rgba(0, 0, 0, 0.2)");

  .ui-dialog-titlebar , .ui-jqdialog-titlebar {
	background-color: #F1F1F1;
	font-size: @base-font-size + 3;
	color: @widget-header-color;
	padding: 0;
 }
 .ui-dialog-title , .ui-jqdialog-title {
	float: none !important;//to override .RTL's
	width: auto;
 }
 .widget-header {
	margin:0;
	border-width:0 0 1px 0;
  }
 
 
 .ui-dialog-buttonpane , .ui-jqdialog-buttonpane {
	background-color:@modal-footer-bg;
	border-top:1px solid @modal-footer-border;
	button {
		font-size: @base-font-size + 1;
	}
 }
 
 .ui-dialog-titlebar-close , .ui-jqdialog-titlebar-close {
	border:none;
	background:transparent;
	opacity:0.4;
	color:@btn-danger;
	padding:0;
	top:50%;
	right:8px !important;
	text-align:center;
	
	&:before {
		content: "\f00d";
		display: inline;
		font-family: FontAwesome;
		font-size: @font-size-jquery-dialog-close;
	}
	&:hover {
		opacity:1;
		text-decoration:none;
		padding:0;
	}
	
	.ui-button-text {
		text-indent:0;
		visibility:hidden;
	}
 }

 .widget-header .ui-dialog-titlebar-close , .widget-header .ui-jqdialog-titlebar-close {
	right:10px !important;
 }

}




/* accordion */
.ui-accordion {
	.ui-accordion-header {
		color:@accordion-header-text;
		font-weight:normal;
		background-color:#F9F9F9;

		border:1px solid @accordion-border;

		padding:8px 8px 9px 24px;
		
		&:hover {
			color:@accordion-header-text-hover;
			background-color:@accordion-header-hover-bg;
		}
		
		&.ui-state-active {
			color:@tab-hover-color;
			background-color:@accordion-active-bg;
			position:relative;
			font-weight:bold;
		}
		
		.ui-accordion-header-icon {
			text-indent: 0;
			margin-top: 0;
			
			position: absolute;
			left: 10px;
			top: 7px;
			
			&:before {
				 display: inline;
				 font-family: FontAwesome;
				 font-size: @base-font-size + 2;
				 content: "\f0da";
			}
		}
		&.ui-state-active .ui-accordion-header-icon:before {
			content:"\f0d7";
			font-weight:normal;
		}
	}
	
	.ui-accordion-content {
		border:1px solid @accordion-border;
		border-top-width:0;
		padding:11px 16px;
	}
}





/* tabs */
.ui-tabs {
	.ui-tabs-nav {
		padding:0;
		border-bottom:1px solid #C5D0DC;
		li.ui-state-default > a{
			background-color: #F9F9F9;
			border: 1px solid #C5D0DC;
			border-bottom-width:0;
			
			color: #999;
			line-height: 16px;
			margin-right: -1px;
			position: relative;
			z-index: 11;
			
			padding:8px 12px;
			
			position:relative; top:2px;	
		}
		li > a:focus {
			outline:none;
		}
		
		li.ui-tabs-active > a{
			background-color:#FFF;
			
			border:1px solid;
			border-color:#4C8FBD #C5D0DC transparent;
			border-top-width:2px;
			
			.box-shadow(~"0 -2px 3px 0 rgba(0, 0, 0, 0.15)");
			color:#576373;
			
			position:relative; top:1px;
		}
	}
	
	.ui-tabs-panel {
		border:1px solid #C5D0DC;
		border-top-width:0;
		margin:0;
		left:auto; right:auto;
		top:auto; bottom:auto;
	}
}







/* menu */
.ui-menu {
	.box-sizing(content-box);

	width:150px;
	.box-shadow(@dropdown-shadow);
	background-color:#FFF;
	border:1px solid rgba(0, 0, 0, 0.2);
	padding:3px;
	

	.ui-state-focus, .ui-state-active {
		margin: auto;
	}
	
	.ui-menu-item {
		padding: 5px 10px 6px;
		color: #444;
		cursor: pointer;
		display: block;
		
		.box-sizing(inherit);
	}
	
	.ui-menu-item .ui-menu-icon {
		float: right;
		position: relative;
		//top: 2px;
		left: auto;
		right: 4px;
		bottom: auto;
		text-indent: 0;

		&:before {
			content: "\f105";
			font-family: FontAwesome;
			font-size: @base-font-size + 1;
			display: inline;
		}
	}
	
	.ui-menu-item:hover, .ui-state-focus , .ui-state-active {
		& , > .ui-menu-icon {
			text-decoration: none;
			background-color: @dropdown-menu;
			color: #FFF;
			margin: auto;
			font-weight: normal;

			.ui-menu-icon {
				color: #FFF;
			}
		}
	}
	
	.ui-state-disabled, .ui-state-disabled .ui-menu-icon {
		color: #999;
		cursor: default;
	}
	.ui-state-disabled:hover, .ui-state-disabled.ui-state-focus , .ui-state-disabled.ui-state-active {
		& , .ui-menu-icon {
			background-color: #FFF;
			color: #999;
		}
	}

}



/* auto complete */
.ui-autocomplete {
	background-color:#FFF;
	border:1px solid rgba(0, 0, 0, 0.2);
	.box-shadow(@dropdown-shadow);
}
.ui-autocomplete-category {
	padding:6px;
	position:relative;
	background-color:@accordion-active-bg;
	color:@ace-blue;
	font-weight:bolder;
	border:1px solid #DAE6ED;
	border-width:1px 0;
}









// spinner
.ui-spinner-button {
    border-width:0 !important;
    font-size: @font-size-jquery-spinner;
    height: 16px;
    line-height: 16px;
    padding: 0;
    width: 18px;

	color: #FFFFFF !important;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
	
	display:inline-block;
	position:absolute;
	text-align:center;
	padding:0;

	 > .@{icon} {
		width:18px;
		display:inline-block;
	}
}
.ui-spinner-up {
	top:0;
	right:5px;
}
.ui-spinner-down {
	bottom:3px;
	right:5px;
}


.ui-spinner-input {
	margin-top: 0;
	padding: 5px;
	max-width: 100px;
	font-size: @font-size-text-input;
}


.ui-tooltip {
	background-color:#444;
	color:#FFF;
}

.ui-progressbar {
	background-color: @progress-bg;
	height:22px;
	.ui-progressbar-value {
		margin:0;
		
		&[class="progress-bar"] {
			background-color:@progress-color;
		}
	}
}



//selectmenu
.ui-selectmenu-button {
 border: 1px solid #aaa;
 &[aria-expanded=true] {
	border-color: #4492C9;
 }
 
 span.ui-icon {
	text-indent: 0;
	margin-top: -10px;
 }
 .ui-icon:before {
	content: "\f0d7";
	display: inline-block;
  
	color: #888;
	font-family: FontAwesome;
	font-size: @base-font-size + 1;
 }
}


}
.enable_plugin_jquery_ui();