.enable_collapsible_responsive_menu() when(@enable-collapsible-responsive-menu = true) {

//3rd style responsive menu (the collapsible menu)

@media only screen and (min-width: @grid-float-breakpoint) {
 .sidebar.navbar-collapse {
	max-height: none;
 }
}

@media only screen and (max-width: @grid-float-breakpoint-max) {
 .sidebar.navbar-collapse {
	position: relative;
	float: none !important;//to override .rtl's
	margin-top: auto !important;//to override inline h-sidebar margin-top!
	z-index: 1;//for box-shadow to be visible

	width: 100% !important;
	max-height: @pre-scrollable-max-height;
	margin-left: 0;
	margin-right: 0;

	&:before {
		display: none;
	}

	.nav-list > li:last-child {
		border-bottom-width: 0;
	}
	.nav-list > li > a {
		padding-top: 10px !important;//to override .compact, etc
		padding-bottom: 10px !important;

		line-height: 20px;
		height: @nav-item-height + 2;
	}
	.nav-list > li:before {
		height: @nav-item-height + 4;
	}
	.nav-list > li.active:after {
		display: none;
	}

	//-li > .arrow
	li > .arrow {
		display: none !important;
	}

	.nav-list li > .submenu {
		border-left-width: 0;
	}

	.sidebar-toggle {
		display: none;
	}
	.sidebar-shortcuts {
		padding: 3px 0 6px;
		max-height: none;
		border-bottom-width: 0;
	}

	+ .main-content {
		margin-left: 0 !important;
		margin-right: 0 !important;//to override rtl's
		.breadcrumb {
			margin-left: @breadcrumb-margin-left;
		}
	}
	
	//first sidebar scroll style
	.nav-wrap + .ace-scroll {
		display: none;
	}

	&.menu-min {
		.nav-list > li > .submenu {
			position: relative;
			left: auto;
			top: auto;
			width: auto;
			z-index: auto;
			
			margin-top: auto;
			padding-top: 0;
			padding-bottom: 0;

			border-width: 1px 0 0 0 !important;
			.box-shadow(none) !important;//to override .rtl's

			&:before {
				display: block;
			}
			> li:before {
				display: block;
			}
			li > a  {
				> .menu-icon {
					left: 10px;
				}
			}
			
			//hide the extra pixel
			&:after {
				display: none;
			}
		}
		
		.nav-list > li > .submenu li > a {
			padding: 7px 0 9px 37px;
		}
		.nav-list > li > .submenu li > .submenu > li > a {
			margin-left: 20px;
			padding-left: 22px;
		}
		.nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
			margin-left: 20px;
			padding-left: 38px;
		}
		
		
		
		.nav-list > li > a:hover:before {// the left side border on hover
			width:3px;
		}
		
		.nav-list > li > a {
			text-align: left;
			padding-left: 7px;
		}
		.nav-list > li > a > .menu-icon {
			display: inline-block;
			width: auto;
			margin-right: 2px;
		}
		.nav-list > li > a > .menu-text {
			position: relative;
			display: inline;
			line-height: normal;
			padding-left: 0;
			height: auto;
						
			top: auto;
			left: auto;
			z-index: auto;
			width: auto;

			border-width: 0 !important;
			background-color: transparent !important;

			.box-shadow(none) !important;
		}
		.nav-list a > .arrow {
			display: block;
		}
		
		.nav-list > li:hover > .submenu {
			display: none !important;
		}
		.nav-list > li.active > .submenu	{
			display: block !important;
		}
		.nav-list > li > .submenu.nav-show {
			display: block !important;
		}
		.nav-list > li > .submenu.nav-hide {
			display: none !important;
		}

		.sidebar-shortcuts-large {
			display: block;
			position: static;
			.box-shadow(none) !important;//to override .rtl's;
			border-width: 0 !important;
			background-color: transparent !important;
			width: auto;

			padding: 0;//override that of .menu-min
		}
		.sidebar-shortcuts-mini {
			display: none;
		}
	}//menu-min


	.nav-list a {
	  .badge , .label {
		position: relative;
		top: -1px;
		right: auto;
		left: 4px;
	  }
	}

	.nav-list > li.active:after, 
	.nav-list li.active > a:before,
	.nav-list li.active > a:after {
		display: none !important;
	}

	
	//.highlight
	.nav-list > li.highlight.active > a {
		&:before, &:after {
			display: none !important;
		}
	}
 }
}


//
@media (max-width: @grid-float-breakpoint-max) and (max-height: 400px){
 .sidebar.navbar-collapse {
	max-height: 300px;
 }
}
@media (max-width: @grid-float-breakpoint-max) and (max-height: 350px){
 .sidebar.navbar-collapse {
	max-height: 250px;
 }
}
@media (max-width: @grid-float-breakpoint-max) and (max-height: 300px){
 .sidebar.navbar-collapse {
	max-height: 200px;
 }
}
@media (max-width: @grid-float-breakpoint-max) and (max-height: 250px){
 .sidebar.navbar-collapse {
	max-height: 150px;
 }
}


.enable_hover_submenu_responsive_style_3() when(@enable-submenu-hover = true) {
@media (min-width: @screen-hover-menu) and (max-width: @grid-float-breakpoint-max) {
  .sidebar.navbar-collapse {
	.nav-list > li > .submenu li.hover > .submenu > li > a,
	.nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a {
			margin-left: 20px !important;
	}
  }
}
}
.enable_hover_submenu_responsive_style_3();


 //if .sidebar is .navbar-collapse, and .navbar is fixed, make .sidebar fixed as well
 @media (max-width: @grid-float-breakpoint-max) {
   .navbar-fixed-top + .main-container .sidebar.navbar-collapse {
		position: fixed;
		z-index: @zindex-sidebar-fixed;
   }
 }
 @media (min-width: @screen-sm-min) and (max-width: @grid-float-breakpoint-max) {
   .navbar-fixed-top + .main-container.container .sidebar.navbar-collapse {
		width: @container-sm !important;
   }
 }

}
.enable_collapsible_responsive_menu();


