//the notifications and new message buttons
.ace-nav {
 height: 100%;
 margin: 0 !important;
 
 > li {
	line-height: @navbar-min-height;
	height: @navbar-min-height;

	border-left: 1px solid #E1E1E1;
	padding: 0;

	position: relative;
	float: left;
	
	&:first-child {
	  border-left-width: 0;
	}
	
	> a {
		background-color: @ace-nav-default;
		color: #FFF;

		display: block;
		line-height: inherit;
		text-align: center;

		height: 100%;
		width: auto;
		min-width: 50px;
		padding: 0 8px;

		position: relative;

		> .@{icon} {
			display: inline-block;
			font-size: @font-size-navbar-user-button-icon;
			color: #FFF;
			text-align: center;
			width: 20px;
		}

		> .badge {
			position: relative;
			top: -4px;
			left: 2px;

			padding-right: 5px;
			padding-left: 5px;
		}
	}

	> a:hover , > a:focus, &.open > a {
		background-color: desaturate(darken(@ace-nav-default, 4%), 4%);
		color: #FFF;
	}

	//different colors
	.ace-nav-color(@color) {
	  @ace-nav-class: ~`"ace-nav-@{color}"`;
	  @ace-nav-bg: ~`"ace-nav-@{color}"`;
	  @ace-nav-cl: @@ace-nav-bg;

	  &.@{color} > a {
		background-color:@ace-nav-cl;
	  }
	  &.@{color} > a:hover , &.@{color} > a:focus, &.open.@{color} > a {
		background-color: desaturate(darken(@ace-nav-cl, 4%), 4%);
	  }
	}

	.ace-nav-color(~"blue");
	.ace-nav-color(~"grey");
	.ace-nav-color(~"purple");
	.ace-nav-color(~"green");
	.ace-nav-color(~"light-blue");
	.ace-nav-color(~"light-blue2");
	.ace-nav-color(~"red");
	.ace-nav-color(~"light-green");
	.ace-nav-color(~"light-purple");
	.ace-nav-color(~"light-orange");
	.ace-nav-color(~"light-pink");
	.ace-nav-color(~"dark");
	.ace-nav-color(~"white-opaque");
	.ace-nav-color(~"dark-opaque");

	//some extra ones
    &.transparent > a {
		background-color: transparent;
	}
	&.transparent > a:hover, &.transparent > a:focus, &.open.transparent > a {
		background-color: rgba(0,0,0,0.1);
	}
	
    &.light-10 > a {
		background-color: rgba(255,255,255,0.1);
	}
	&.light-10 > a:hover, &.light-10 > a:focus, &.open.light-10 > a {
		background-color: rgba(255,255,255,0.2);
	}

	&.dark-10 > a {
		background-color: rgba(0,0,0,0.1);
	}
	&.dark-10 > a:hover, &.dark-10 > a:focus, &.open.dark-10 > a {
		background-color: rgba(0,0,0,0.2);
	}

	//margins
	.marginX (@index) when (@index > 0) {
		&.margin-@{index} { margin-left: unit(@index,px); }
		.marginX(@index - 1);
	}
	.marginX(4);
	&.no-border {
		border-width: 0 !important;
	}

	///
	.dropdown-menu {
		z-index: @zindex-navbar-fixed + 1;
	}
 }
 
 .nav-user-photo {
	 margin: -4px 8px 0 0;

	 border-radius: 100%;
	 border: 2px solid #FFF;

	 max-width: 40px;
 }
 
 
  li.dropdown-footer  a .@{icon} {
	// the arrow indicating "See more" on each dropdown , and the icons of user menu */
	display: inline-block;
	width: 1.25em;
	text-align: center;
  }

}



//let dropdowns become like modals in small devices!
@media only screen and (max-height: @screen-qhd-width) {
//@media only screen and (max-height: @screen-topbar-down) {
 .ace-nav > li > .dropdown-menu {
   position: fixed;
   margin-top: auto;
   z-index: @zindex-modal + 2;

    &:before, &:after {
	  display: none;
    }
 }
 .ace-nav .user-menu > li > a {
	padding-top: 6px;
	padding-bottom: 6px;
 }
 .ace-nav > li.open:before {
	background-color: #000;
	opacity: 0.5;
	border-width: 0;

	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	content: "";
	display: block;
	height: 2000px;
	width: auto;

	z-index: @zindex-modal + 1;
 }
}