//sidebar toggle
.sidebar {

.sidebar-toggle {
 border-style: solid;
 border-width: 0 0 1px;

 text-align: center;
 padding: 3px 0;

 position: relative;

 > .@{icon} {
	 padding: 0 5px;
	 line-height: 18px;
	 
	 cursor: pointer;
	 font-size: @font-size-sidebar-minimize-icon;
		 
	 border-radius: 100%;
	 border: 1px solid;

	 position: relative;
 }

 &:before {
	content: "";
	display: block;

	height: 0;
	border-top: 1px solid; 
	border-color: inherit;

	position: absolute;
	left: 15px;
	right: 15px;
	top: 13px;
 }
}





//sidebar shortcuts icon
.sidebar-shortcuts {
	text-align: center;

	min-height: @breadcrumb-height - 1;
	margin-bottom: 0;
	overflow: hidden;

	position: relative;
	border: 0 solid;
}
.sidebar-shortcuts:empty {
	display: none;
}


.sidebar-shortcuts-large {
	line-height: @breadcrumb-height - 4;

	> .btn {
		text-align:center;
		width: 41px;
		line-height: 24px;

		padding: 0;
		border-width: 4px;

		> .@{icon} {
			margin: 0;
		}
	}
}

.sidebar-shortcuts-mini {
	display: none;
	font-size: 0;
	width: 42px;

	padding-top: 2px;
	padding-bottom: 2px;


	> .btn {
		border-width: 0;// !important;
		font-size: 0;
		line-height: 0;
		padding: 8px;
		margin: 1px;

		border-radius: 0;
		.opacity(0.85);
	}
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
    ::safari-only, .sidebar-shortcuts-mini > .btn {
		//safari only
		margin: 0;
    }
}

}