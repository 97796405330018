.enable_invoice() when(@enable-invoice = true) {
//invoice
.invoice-info {
  line-height: 24px !important;
  color: #444;
  vertical-align: bottom;
  margin-left: 9px;
  margin-right: 9px;
}
.invoice-info-label {
  display: inline-block;
  max-width: 100px;
  text-align: right;
  font-size: @font-size-invoice-data;
}
.invoice-box .label-large[class*="arrowed"]{
  margin-left: 11px !important;
  max-width: 95%;
}

}
.enable_invoice();