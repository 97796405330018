@scroll-bar-width: 8px;
@scroll-thin-width: 6px;



.ace-scroll {
	overflow: hidden;
}
.scroll-content {
	position: static;
	overflow: hidden;
}
.scroll-disabled.ace-scroll {
  &, & .scroll-content {
	overflow: visible;
  }
}


.scroll-track {
  position: absolute;
  top: auto;
  bottom: auto;
  right: 0;
  
  //width: @scroll-bar-width;
  height: auto;// will be determined at runtime
  background-color: #E7E7E7;

  z-index: 99;//to appear above content
  
  width: 0;
  opacity: 0;
}
.scroll-bar {
 position: absolute;
 top: 0;
 left: 0;
 width: inherit;
 background-color: #ACE;

 background: saturate(darken(#CFDFEA , 5%), 5%);
}


.scroll-track.scroll-hz {
 top: auto;
 left: auto;
 right: auto;
 bottom: 0;
 
 height: @scroll-bar-width;
 width: auto;// will be determined at runtime
}
.scroll-hz .scroll-bar {
 height: inherit;
 width: auto;
}





.scroll-track.scroll-active {
	.transition(~"width 0.25s ease 0.75s, opacity 0.25s ease 0.75s");
}

.ace-scroll:hover .scroll-active,
.scroll-active.scroll-track:hover,
.scroll-active.scroll-track.scroll-hover,
.scroll-active.scroll-track.active,
.scroll-active.scroll-track:active
{
	width: @scroll-bar-width;
	opacity: 1;

	.transition-duration(0.15s);
	.transition-delay(0s);
}



.scroll-track.active > .scroll-bar {
 transition-property: none !important; 
 transition-duration: 0s !important; 
}






//optional styling classes
.scroll-track.scroll-margin {
 margin-left: -1px;
}
.scroll-track.scroll-left {
  right: auto;
  left: 0;
  &.scroll-margin {
	margin-left: 1px;
  }
}

//for horizontal
.scroll-track.scroll-top {
  bottom: auto;
  top: 0;

  &.scroll-margin {
	top: 1px;
  }
}

.scroll-dark {
  .scroll-bar {
    background-color: transparent;
	background-color: rgba(0,0,0,0.25);
    filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#40000000',GradientType=0 )";
  }
  .scroll-track& {
	background-color: transparent;
	background-color: rgba(0,0,0,0.15);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='#26000000', endColorstr='#26000000',GradientType=0 )";
  }
}
.scroll-light {
  .scroll-bar {
	background-color: transparent;
	background-color: rgba(0,0,0,0.14);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='#24000000', endColorstr='#24000000',GradientType=0 )";
  }
  .scroll-track& {
	background-color: transparent;
    background-color: rgba(0,0,0,0.07);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='#12000000', endColorstr='#12000000',GradientType=0 )";
  }
}
.scroll-white {
  .scroll-bar {
	background-color: transparent;
	background-color: rgba(255,255,255,0.33);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='#55FFFFFF', endColorstr='#55FFFFFF',GradientType=0 )";
  }
  .scroll-track& {
	background-color: transparent;
	background-color: rgba(255,255,255,0.2);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='#33FFFFFF', endColorstr='#33FFFFFF',GradientType=0 )";
  }
}


.no-track.scroll-track {
  background-color: transparent;
  filter: ~"progid:DXImageTransform.Microsoft.gradient( enabled=false )";
}


.scroll-visible {
	.scroll-track& {
		opacity: 1;
		width: @scroll-bar-width;
	}
	.scroll-hz& {
		height: @scroll-bar-width;
		width: auto;
	}
}


.scroll-thin.scroll-track {
	&:hover, &.scroll-hover, &:active, &.active, &.scroll-visible {
		width: @scroll-thin-width;
	}
}
.ace-scroll:hover .scroll-thin.scroll-track {
	width: @scroll-thin-width;
}

//horizontal
.scroll-thin.scroll-hz {
	&:hover, &.scroll-hover, &:active, &.active, &.scroll-visible {
		width: auto;
		height: @scroll-thin-width;
	}
}
.ace-scroll:hover .scroll-thin.scroll-hz {
	width: auto;
	height: @scroll-thin-width;
}




//style like latest version of Chrome
.scroll-chrome.scroll-active {
  .scroll-bar {
	background-color: transparent;
	width: 11px;
	
	&:before {
		display: block;
		content: "";
		position: absolute;
		top: 1px;
		bottom: 3px;
		left: 1px;
		right: 1px;
		
		background-color: #D9D9D9;
		border:1px solid #BBB;
		border-radius: 1px;
	}
  }
  .scroll-track {
	width: 12px;
	background-color: #F2F2F2;
	border: 1px solid;
	border-width: 1px 0 1px 1px;
	border-color: #E6E6E6 transparent #E6E6E6 #DBDBDB;
	
	&:hover {
		.scroll-bar:before {
			background-color: #C0C0C0;
			border-color: #A6A6A6;
		}
	}
	
	&.active {
		.scroll-bar:before {
			background-color: #A9A9A9;
			border-color: #8B8B8B;
		}
	}
  }
}




.scroll-active.scroll-track.idle-hide {
  opacity: 0;
  filter: ~"alpha(opacity=0)";
  width: @scroll-bar-width;//to make it hoverable

  &.not-idle {
	 width: @scroll-bar-width;
	 opacity: 1;
	 filter: ~"alpha(opacity=100)";
  }
}

.scroll-active.scroll-thin.scroll-track.idle-hide {
	width: @scroll-thin-width;
}
.scroll-active.scroll-chrome .scroll-track.idle-hide {
	width: 12px;
}



//special nav-list scroller case
.nav-wrap + .scroll-active .scroll-track {
	width: @scroll-bar-width;
}

.nav-scroll.scroll-active .scroll-track 
{
	width: @scroll-bar-width;
	right: 0;
}
.nav-wrap:hover + .scroll-active .scroll-track ,
.nav-scroll.scroll-active:hover .scroll-track
{
	width: @scroll-bar-width;
	opacity: 1;

	.transition-duration(0.15s);
	.transition-delay(0s);
}
.nav-scroll.scroll-active:hover .scroll-track {
	width: @scroll-bar-width;
}



.nav-scroll.ace-scroll {
 & , & .scroll-content {
	overflow: hidden;
 }
}
.nav-scroll.ace-scroll.scroll-disabled {
 & , & .scroll-content {
	overflow: visible;
 }
}


.scroll-track.scroll-detached {
  position: absolute;
  z-index: @zindex-navbar-fixed - 1;
  bottom: auto;
  right: auto;
}
