//multiple sidebar

.main-content-inner {
  float: left;
  width: 100%;
}

.main-content .sidebar {
 &:before {
   top: 150px;
 }
}



@media only screen and (min-width: @grid-float-breakpoint) {
 body.mob-safari {
	.sidebar.h-sidebar + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
		top: @navbar-min-height + 17 + 69;
	}
	.sidebar.h-sidebar.no-gap + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
		top: @navbar-min-height + 69;
	}
	.sidebar.h-sidebar.menu-min + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
		top: @navbar-min-height + 17 + 44;
	}
	.sidebar.h-sidebar.menu-min.no-gap + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
		top: @navbar-min-height + 44;
	}
 }
}


.enable_horizontal_menu_multiple() when (@enable-horizontal-menu = true) {
 @media only screen and (min-width: @grid-float-breakpoint) {
	 .h-navbar.navbar-fixed-top + .main-container .sidebar:not(.h-sidebar) {
		&:before {
			top: 150px;
		}
		
		.nav-list {
			padding-top: 3px;
		}
		.sidebar-shortcuts ~ .nav-list, .sidebar-shortcuts ~ .nav-wrap-up .nav-list {
			padding-top: 0;
		}
		.sidebar-shortcuts {
			padding-top: 3px;
			min-height: 43px;
		}
		
		&.menu-min .sidebar-shortcuts-large {
			line-height: 40px;
		}
	 }
 
	 
	 .h-navbar.navbar-fixed-top + .main-container .main-content .sidebar {
		&:not(.h-sidebar) .nav-list {
			padding-top: 0;
		}
		
		&.sidebar-fixed {
			z-index: @zindex-sidebar-fixed - 1;
		}
	 }
	 

	.main-content .h-sidebar {
		padding-top: 1px;
		margin-top: 0;

		&:before {
			display: none;
		}
		+ .page-content {
			margin-left: auto;
		}
		
		&.sidebar-fixed {
			left: @sidebar-width;
			+ .page-content {
				margin-top: 74px;
			}
			&.menu-min + .page-content {
				margin-top: 49px;
			}
		}
		
		&.menu-min .nav-list > li > a {
			padding: 8px 12px 7px;//for better sidebar1 & sidebar2 alignnment?
		}
	}

	.sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
		left: @sidebar-compact-width;
	}
	.sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
		left: @sidebar-min-width;
	}
	
	.navbar.navbar-fixed-top + .main-container .main-content .h-sidebar.sidebar-fixed {
		padding-top: 3px;
	}
 }//@media



 .enable_container_multiple_menu() when(@enable-container = true) {
	.container.main-container {
	 
	   .sidebar + .main-content .h-sidebar.sidebar-fixed {
			left: auto;
			right: auto;
		}
	
	 
	 @media (min-width: max(@screen-sm-min , @grid-float-breakpoint, @screen-compact-menu)) {
		.sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
			width: @container-sm - @sidebar-compact-width;
		}
	 }
	 @media (min-width: max(@screen-sm-min , @grid-float-breakpoint)) {
		.main-content .h-sidebar.sidebar-fixed {
			width: @container-sm;
		}
		.sidebar + .main-content .h-sidebar.sidebar-fixed {
			width: @container-sm - @sidebar-width;
		}
		.sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
			width: @container-sm - @sidebar-min-width;
		}
	 }
	 
	 
	 @media (min-width: max(@screen-compact-menu, @screen-md-min)) {
	  .sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
		width: @container-md - @sidebar-compact-width;
	  }
	 }
	 @media (min-width: @screen-md-min) {
		.main-content .h-sidebar.sidebar-fixed {
			width: @container-md;
		}
		.sidebar + .main-content .h-sidebar.sidebar-fixed {
			width: @container-md - @sidebar-width;
		}
		.sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
			width: @container-md - @sidebar-min-width;
		}
	 }
	 
	 
	 @media (min-width: max(@screen-compact-menu, @screen-lg-min)) {
		.sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
			width: @container-lg - @sidebar-compact-width;
		}
	 }
	 @media (min-width: @screen-lg-min) {
		.main-content .h-sidebar.sidebar-fixed {
			width: @container-lg;
		}
		.sidebar + .main-content .h-sidebar.sidebar-fixed {
			width: @container-lg - @sidebar-width;
		}
		.sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
			width: @container-lg - @sidebar-min-width;
		}
	 }
	}

 }
 .enable_container_multiple_menu();

}
.enable_horizontal_menu_multiple();




@media only screen and (max-width: @grid-float-breakpoint-max) {
 .main-container .main-content .sidebar {
	&.sidebar-fixed {
		z-index: @zindex-sidebar-fixed - 1;
	}
 }
 
  .navbar-fixed-top + .main-container .main-content .sidebar {
	position: fixed;
	z-index: @zindex-sidebar-fixed - 1;
  }
}