@ace-settings-box-border: #FFB34B;


// other page sections

//the button which scrolls page up
.btn-scroll-up {
  border-width: 0;
  //position: absolute;
  position: fixed;
  right: 2px;
  z-index: 99;
  
  .transition-duration(0.3s);
  //.transition-property(opacity, bottom);
  
  .opacity(0);
  bottom: -24px;
  visibility: hidden;
 
  &.display {
	.opacity(0.7);
	bottom: 2px;
	visibility: visible;
  }
  &:hover {
	.opacity(1);
  }
  &:focus {
	outline: none;
  }
}

.enable_container_btn_scroll_up() when(@enable-container = true) {
@media (min-width: @screen-sm-min) {
 .main-container.container > .btn-scroll-up {
	right: auto;
	margin-left: @container-sm - 30;
 }
}
@media (min-width: @screen-md-min) {
 .main-container.container > .btn-scroll-up {
	right: auto;
	margin-left: @container-md - 30;
 }
}
@media (min-width: @screen-lg-min) {
 .main-container.container > .btn-scroll-up {
	right: auto;
	margin-left: @container-lg - 30;
 }
}
}
.enable_container_btn_scroll_up();





.enable_settings_box() when(@enable-settings-box = true) {
.ace-settings-container {
 position: absolute;
 right: 0;
 top: auto;
 z-index: 12;
}
.btn.btn-app.ace-settings-btn {
 float: left;
 display: block; 
 text-align: center;

 border-radius: 6px 0 0 6px;
 opacity: 0.55;

 vertical-align: top;
 margin: 0;
 
 &:hover , &.open {
	opacity: 1;
 }
 
 &.btn-xs {
	width: 42px;
 }

}

.ace-settings-box {
 display: block;
 float: left;

 max-width: 0;
 max-height: 0;
 overflow: hidden;
 padding: 0;
 .transform(~"translate(0,0)");//needed, don't know why!

 background-color: #FFF;
 border: 0 solid @ace-settings-box-border;
 
 
 .transition(~"max-width 0.25s linear 0s, max-height 0s linear 0.25s, padding 0s linear 0.25s, border-width 0s linear 0.25s");

 &.open {
	max-width: 320px;
	max-height: 1000px;

	padding: 0 14px;
	border-width: 2px;

	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	-o-transition-delay: 0s;
	transition-delay: 0s;
	
	.ace-settings-item {
		z-index: auto;
		min-width: 140px;
	}
 }
 
 .ace-settings-item {
	margin: 6px 0;
	color: #444;
	max-height: 24px;

	position: relative;
	z-index: -1;
	
	white-space: nowrap;

	> label.lbl {
		font-size: @base-font-size;
	}
 }
}

@media (max-width: @screen-xs) {
 .ace-settings-container {
	text-align: right;
 }
 .ace-settings-box {
	float: none !important;//to override .RTL's
	text-align: left;
 }
 .btn.ace-settings-btn {
	float: none !important;//to override .RTL's
	display: inline-block;
 } 
}
@media (max-width: @screen-tiny) {
 .ace-settings-box > .clearfix > .pull-left, .ace-settings-box > .clearfix > .pull-right {
	float: none !important;
 }
}

}
.enable_settings_box();






.grid2, .grid3, .grid4 {
	.box-sizing(border-box);
	display: block;
	margin: 0 1%;
	padding: 0 2%;
	float: left;

	border-left: 1px solid #E3E3E3;
	&:first-child {
		border-left: none;
	}
}

.grid2 {
	width: 48%;
}
.grid3 {
	width: 31.33%;
}
.grid4 {
	width: 23%;
	padding: 0 1%;
}


.draggable-placeholder {
  //for when dragging items around
  border: 2px dashed #D9D9D9 !important;
  background-color: #F7F7F7 !important;
}



// scrollbar
//.slimScrollBar  { .border-radius(0) !important; }
//.slimScrollRail { .border-radius(0) !important; }


// a few small third party css files put here to reduce http file requests
// jquery.easy-pie-chart.css
.easyPieChart, .easy-pie-chart {
    position: relative;
    text-align: center;
	
	canvas {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.knob-container {
	direction: ltr;
	text-align: left;
}







