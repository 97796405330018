@sidebar-menu-min-text-height: (@nav-item-height + 2);

.menu_min() {
	& , &.compact, &.navbar-collapse {
		width: @sidebar-min-width;
	}

	+ .main-content {
		margin-left: (@sidebar-min-width);
	}


	.nav-list a {
	  .badge , .label {
		position: relative;
		top: -1px;
		right: auto;
		left: 4px;
	  }
	}

	.nav-list .submenu .submenu a {
	  .badge , .label {
		top: 0;
	  }
	}

	
	.nav-list > li {
	  > a {
		 position:relative;
		
		 //first reset back to normal, to override things like .compact	 
		 height: @nav-item-height;
		 line-height: (@nav-item-height - 2);
		 padding: 0; 
		 > .menu-icon {
			font-size: @nav-item-icon-size;
			height: auto;
			line-height: inherit;
			display: inline-block;
			vertical-align: sub;
			margin-right: 0;
			width: 44px;
		 }


		 > .menu-text {
			display: none;
			text-align: left;
			
			position: absolute;
			top: -1px;
			left: (@sidebar-min-width - 1);
			z-index: @zindex-submenu - 1;

			width: (@sidebar-width - 14);
			height: @sidebar-menu-min-text-height;
			line-height: (@nav-item-height - 1);
			
			padding-left: 12px;
			border: 1px solid;
		}
		&.dropdown-toggle > .menu-text {
			top:-1px;
			border-width: 1px 1px 0;
		}
		
		.arrow {
			display: none;
		}
	  }

	 &:hover > a > .menu-text,
	 &.hover-show > a > .menu-text {
		display: block;
	 }

	 &.active > a:after {
		border-width: 10px 6px;
		top: 8px;
	 }
	 &.active.open > a:after {
		display: block;
	 }
	 &.active.open li.active > a:after {
		display: none;
	 }



	 > .submenu {
		position: absolute;
		z-index: @zindex-submenu;
		left: (@sidebar-min-width - 1);
		top: -1px;
		
		margin-top: 40px;
		padding-bottom: 2px;

		width: (@sidebar-width - 14);
		display: none !important;


		&:before {
			//hide the tree like submenu in minimized mode
			display: none;
		}
		&:after {
			//extra pixel
			//.submenu's border-top-color overlaps .submenu's border-right-color
			//so we add an extra pixel to change that point to border-right-color
			content: "";
			display: block;
			height: 1px;
			width: 1px;
			
			position: absolute;
			right: -1px;
			top: -1px;
			
			border-right: 1px solid;
			border-right-color: inherit;
		}

		li {
		  &:before {
			display: none;
		  }

		  > a {
			margin-left: 0;
			padding-left: 22px;
			> .menu-icon {
				left: 4px;
			}
		  }
		}
	 }

	 &.hover > .submenu {
		padding-bottom: 0;
	 }


	 &.open > .submenu {//submenu is shown in non-minimized mode
		display: none;
	 }
	 
	 
	 &:hover > .submenu,
	 &.hover-show > .submenu {
		display: block !important;
	 }
  }


  //-li > .arrow
  .nav-list > li > .arrow {
	top: 10px;
	&:after , &:before {
		border-width: 8px;
		left: -16px;
	}
	&:before {
		left: -17px;
	}
  }
  .nav-list li > .arrow {
	right: -1px;
  }
  .nav-list > li:hover > .arrow,
  .nav-list > li.hover-show > .arrow  {
	display: block;
  }
  
  .nav-list > li.pull_up > .arrow {
	 z-index: @zindex-submenu + 1;
	 &:after , &:before {
		border-width: 10px;
		left: -20px;
	 }
	 &:before {
		left: -21px;
	}
  }


  //sidebar shortcuts
  .sidebar-shortcuts {
	position: relative;
	overflow: visible;
	z-index: 1;//to appear above breadcrumbs
  }
  .sidebar-shortcuts-mini {
	display: block;
  }
  .sidebar-shortcuts-large {
	display: none;
	position: absolute;
	top: 0;
	left: @sidebar-min-width - 1;
	
	width: (@sidebar-width - 8);
	
	padding: 0 2px 3px;
	
	border: 1px solid;
	border-top-width: 0;
  }
  .sidebar-shortcuts:hover .sidebar-shortcuts-large,
  .sidebar-shortcuts.hover-show .sidebar-shortcuts-large {
	display: block;
  }

  .sidebar-toggle { //minimized collapse button
	&:before {
		left: 5px;
		right: 5px;
	}
	> .@{icon} {
		font-size: @font-size-sidebar-toggle;
		padding: 0 4px;
		line-height: 15px;

		border-width: 1px;
	}
  }
  



  .nav-list > li > .submenu {
	li > .submenu > li {
		> a {
			//3rd level
			margin-left: 2px;// !important;
			padding-left: 30px;
		}
		> .submenu > li > a {
			//4th level
			margin-left: 2px;// !important;
			padding-left: 45px;
		}
	}

	li.active > a:after {
		display: none;
	}
 }





 .nav-list li.active.open > .submenu > li.active > a:after {
	display: none;
 }
 //hide the active menu indicator on hover (it goes behind the li > .arrow)
 .nav-list > li.active:hover > a:after,
 .nav-list > li.active.hover-show > a:after,
 .nav-list > li.active.open:hover > a:after {
	display: none;
 }
 .nav-list > li.active:hover:after {
	display: none;
 }



}


.enable_sidebar_collapse() when(@enable-sidebar-collapse = true) {
 .sidebar.menu-min {
	.menu_min();

	.enable_submenu_hover_min() when(@enable-submenu-hover = true) {
	  @media (min-width: @screen-hover-menu) {
		  .nav-list li.hover > .submenu {
			margin-left: 0;
		  }
		  .nav-list > li > .submenu li.hover > .submenu > li > a,
		  .nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a {
			margin-left: 0 !important;
		  }
	  }
	 }
	.enable_submenu_hover_min();
	
	

	.enable_compact_menu_min() when(@enable-compact-menu = true) {
	  @media (min-width: @screen-compact-menu) {
		  &.compact .nav-list > li > a {
			height: 39px;
			line-height: 37px;
			padding: 0 16px 0 7px;
			position: relative;
		  }
		  &.compact .nav-list > li > a > .menu-icon {
			font-size: @nav-item-icon-size;
			height: auto;
			line-height: inherit;
			display: inline-block;
			vertical-align: sub;
			width: auto;
		  }
	  }
	 }
	 .enable_compact_menu_min();

 }
}
.enable_sidebar_collapse();