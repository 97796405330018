.enable_onpage_help() when(@enable-onpage-help = true) {

.onpage-help-backdrop {
  position: absolute;
  z-index: 99990;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 
  background-color: #000;
  filter: alpha(opacity=5);
  background-color: rgba(0,0,0,0.05);
}

.onpage-help-section {
  display: block;
  position: absolute;
  z-index: 100000;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4DFFFFFF', endColorstr='#4DFFFFFF',GradientType=0 );
  background-color: rgba(255,255,255,0.3);
 
  border: 1px dashed #8BBCD3;
  border-radius:4px;
 
  transition: background-color 0.2s, border-color 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
 
  text-align: center;
  vertical-align: middle;
 
  outline: none !important;
}
.onpage-help-section > .ie-hover-fix {
 /* ie8-9 fix*/
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  filter: alpha(opacity=1);
}

.onpage-help-section  {
 &:focus, &:active {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33C9D8EA', endColorstr='#33C9D8EA',GradientType=0 );
	background-color: rgba(201,216,234,0.2);
	border-color: #77ACC4;
 }

 &:hover {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66C9D8EA', endColorstr='#66C9D8EA',GradientType=0 );
	background-color: rgba(201,216,234,0.4);
	border-color: #77ACC4;
	border-style: solid;
 }
 
 > .help-icon-1 {
	 font-size: @font-size-onpage-help-icon-1;
	 display: inline-block;
	 position: absolute;
	 z-index: 1;

	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
	 -webkit-transform: translate(-50%, -50%);
	 
	 opacity: 0;
	 filter:alpha(opacity=0);
	 
	 color: #FFF;
	 text-shadow: 0 0 2px black;
	 
	 background-color: #85B8DB;
	 border: 2px solid #FFF;
	 border-radius: 100%;
	 box-shadow: 0 0 2px 1px rgba(0,0,0,0.4);
	 
	 width: 48px;
	 height: 48px;
	 line-height: 46px;
	 
	  transition: opacity 0.2s;
	 -webkit-transition: opacity 0.2s;
 }
 
 &:focus > .help-icon-1,
 &:active > .help-icon-1 {
	opacity: 0.5;
 }
 &:hover > .help-icon-1 {
	opacity: 1;
	filter:alpha(opacity=100);
 }
 


 &.help-section-small  > .help-icon-1 {
	font-size: @font-size-onpage-help-icon-1-small;
	width: 28px;
	height: 28px;
	line-height: 26px;
	border-radius: 12px;
 }
 &.help-section-smaller > .help-icon-1 {
	font-size: @font-size-onpage-help-icon-1-smaller;
	width: 20px;
	height: 20px;
	line-height: 17px;
	border-radius: 8px;
 }
 
 

 > .help-icon-2 {
	 position: absolute;
	 z-index: 2;
	 left: -4px;
	 top: -4px;
	 width: 18px;
	 height: 18px;
	 line-height: 16px;

	 display: block;
	 
	 font-size: @font-size-onpage-help-icon-2;
	 color: orange;
	 background-color: #FFF;
	 border: 1px solid orange;
	 
	 border-radius: 4px;
	 opacity: 1;
	  
	  transition: all 0.2s;
	 -webkit-transition: all 0.2s;
 }
 &:hover > .help-icon-2 {
	color: #59A34E;
	border-color: #59A34E;
	transform: scale(1.25);
	-webkit-transform: scale(1.25);
 }
 
 
}




.onpage-help-modal {
   z-index: 100010;
} 
.onpage-help-modal + .modal-backdrop {
   z-index: 100009;
}


	
.onpage-help-modal-buttons  button{
  border-radius: 100%;
  border-width: 2px !important;
 
  &:focus {
	outline: none !important;
  }
  &.disabled {
	transform: scale(0.9);
	-webkit-transform: scale(0.9);
  }
}



.onpage-help-content {
	max-width: 800px;
	margin: 0 auto;
}

.code-modal.onpage-help-modal {
 .modal-dialog {
	margin-top: 6px;
	margin-bottom: 6px;
 }
 .modal-header {
	padding-top: 6px;
	padding-bottom: 6px;
 }
 .modal-body {
	padding: 10px 12px
 } 
}

.onpage-help-modal pre {
	border-width: 0;
	box-shadow: none;
	border-radius: 0;
	margin-top: 8px;
}

.onpage-help-modal .modal-title code {
  font-size: @base-font-size;
}
.onpage-help-modal .modal-body {
  font-size: @base-font-size + 1;
}


}
.enable_onpage_help();